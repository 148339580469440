@font-face {
  font-family: 'Quicksand';
  src: url('/Quicksand-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Light';
  src: url('/Raleway-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

:root {
  --accent: rgba(253, 177, 177, 1);
}

html, body {
  margin: 0px;
  min-height: 100%;
}

.main-container {
  overflow-x: hidden;
  height: 100vh;
}

.intro-container {
  height: 100vh;
  text-align: center;

  color: white;
  font-family: 'Quicksand';

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro {
  color: var(--accent);
  font-size: 50px;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 3px solid var(--accent); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  width: 320px;
  animation:
    typing 1s steps(10) 1s 1 normal both,
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0px }
  to { width: 320px }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: var(--accent); }
}

.avatar-container {
  height: 180px;
  width: 180px;
  border: 2px transparent;
  border-radius: 40px;
  background-color: var(--accent);
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  height: 150px;
  width: 150px;
  border-radius: 75px;
}

.clickme{
  border: 0px;
  height: 150px;
  width: 150px;

  margin-top: 100px;

  font-family: 'Quicksand';
  cursor: pointer;
  text-decoration: none;
}

.clickme img{
  height:100%;
  width: 100%;
}

.takemetothetop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;

  background-color: #ffffff;
  box-shadow: -5px -5px rgb(0,0,0,0.3);

  color: var(--accent); /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 50%; /* Rounded corners */
  font-size: 18px;
}

.sticky-navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 110px;
  z-index: 99;
  background-color: #ffffff;
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 18px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: 'Raleway Light';
  font-size: 30px;
  color: var(--accent);
}


.skills-container {
  min-height: 100%;
  background-color: var(--accent);

  color: white;
  font-family: 'Raleway Light';
  font-size: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.loader-container-parent{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.loader-container {
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);

  margin: 15px;
  padding: 10px;

  font-family: 'Quicksand';
  font-size: 26px !important;
  color: var(--accent);
}

.small-loader-container{

  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);

  margin: 15px;
  padding: 2px;

  font-family: 'Quicksand';
  font-size: 16px !important;
  color: var(--accent);
  text-align: center;
}

.small-loader {
  border-radius: 50%;

  min-width: 90px;
  min-height: 90px;

  max-width: 90px;
  max-height: 90px;
}

.small-loader text{
  font-family: 'Quicksand';
  font-size: 1px !important;
  height: 200px !important;
  width: 200px !important;
}

.loader {
  border-radius: 50%;

  max-width: 150px;
  max-height: 150px;
}

.resume-btn-container{

  display: flex;
  justify-content: center;

  height: 50px;
  width: 200px;

  background-color: white;
  border-radius: 50px;
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);

  margin: 20px;
  padding: 5px;
}

.resume-btn {
  height: 100%;
  width: 100%;
  border-radius: 50px;

  border: 2px solid var(--accent);
  background-color: white;
  outline: none;

  color: var(--accent);
  font-size: 20px;
  font-family: 'Raleway Light';

  cursor: pointer;
  text-decoration-color: none;
}

.resume-btn-container a {
  border: none;
  color: var(--accent);
  font-size: 20px;
  font-family: 'Raleway Light';
  cursor: pointer;
  text-decoration-color: none;
  outline: none;
}

.projects-container {

  min-height: 100%;

  background-color: white;
  color: var(--accent);
  font-family: 'Raleway Light';
  font-size: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.project-card-container{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 15px;

  border: 2px solid var(--accent);
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);
  border-radius: 50%;

  min-height: 200px;
  min-width: 200px;

  max-height: 200px;
  max-width: 200px;

  font-family: 'Quicksand';
  font-size: 0.5em !important;
  color: var(--accent);
  text-align: center;
}

.project-text {
  background-color: var(--accent);
  color: white;

  min-height: 180px;
  min-width: 180px;

  max-height: 180px;
  max-width: 180px;
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background-size: contain;
  background-position: center;
  background-repeat: repeat;

  cursor: grab;
}

.project-text a {
  text-decoration: none;
  color: white;
}

.project-hint{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  font-family: 'Raleway Light';
  font-size: 15px;
  color: var(--accent);
}

.posts-container {
  min-height: 100%;
  background-color: var(--accent);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  text-decoration: none;
  color: white;
  font-family: 'Raleway Light';
  font-size: 30px;

  border: 4px solid white;
  padding: 15px;
  margin: 10px;
  /* background-color: grey; */
}

.post-card img {
  height: 200px;
  width: 200px;
  margin: 5px;
}

.post-card p, .post-card a {
  padding: 5px;
}
.post-card p {
  font-family: 'Quicksand';
}

.post-card a {
  border: none;
  color: #FFFFFF;
  font-size: 20px;
  font-family: 'Raleway Light';
  background-color: var(--accent);
  cursor: pointer;
  text-decoration-color: none;
  outline: none;
}

.gallery-container {

  background-color: white;
  color: var(--accent);
  font-family: 'Raleway Light';
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  padding: 100px;
}

.gallery-card-container {
  margin: 15px;

  height: 100%;
  min-width: 300px;
  max-width: 400px;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  border: 1px solid rgb(0,0,0,0.2);
  border-style: outset;
  box-shadow: 2px 1px 2px 1px rgb(0,0,0,0.2);
}

.gallery-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 10px;
}

.gallery-card img {
  width: 100%;
}

.gallery-card-container p {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Quicksand';

  color: white;
  background-color: var(--accent);

  max-height: 100%;
  padding: 10px;
  margin: 0;

  text-align: center;
}

.gallery-link {
  text-decoration: none;
}

.footer-container {
  min-height: 30%;
  background-color: var(--accent);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: white;
  font-family: 'Raleway Light';
}

.social-bar-container{
  color: white;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.footer-icons {
  height: 100%;
}

.social-bar-container img {
  height: 50px;
  margin: 5px;
  padding: 5px;
}

.footer-text {

}
